.wrapper {
    display: flex;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    width: 1080px;
}

.nameWrapper {
    padding: 5px 15px;
    border-right: 1px solid #dbdbdb;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.levelsWrapper {
    display: flex;
    flex-direction: column;
}

.blockWrapper {
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    padding: 5px 0px;
    height: 100%;
    border-right: 1px solid #dbdbdb;

    &:last-child {
        border-bottom: 0px;
    }
}

.propWrapper {
    padding: 5px 15px;
    width: 120px;
    border-right: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.propLastWrapper {
    padding: 5px 15px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.nameLastWrapper {
    padding: 20px 10px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}