.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.updateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.currentVersion {
    margin-top: 10px;
    color: red;
}

.newVersion {
    color: green;
}

.updateButton {
    margin-top: 10px;
}

.updateUpToDateContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.upToDateVersion {
    margin-top: 10px;
    color: green;
}

.missingSdCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.missingSdCard {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
}

.refreshButton {
    margin-left: 10px;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.progress {
    margin-top: 10px;
    font-weight: 500;
}