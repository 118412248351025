.mainContainer {
    padding: 12px 15%;

    @media (max-width: 1600px) {
        padding: 12px 10%;
    }

    @media (max-width: 1420px) {
        padding: 12px 5%;
    }

    @media (max-width: 1250px) {
        padding: 12px 30px;
    }
}

.settingsContainer {
    margin-top: 30px;
}

.card {
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    font-weight: 500;
}

.cardIcon {
    margin-right: 10px;
}

.tabDesc {
    margin-top: 10px;
}