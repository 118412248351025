.wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.headerWrapper {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 1080px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.header {
    width: 120px;
    border-right: 1px solid #dbdbdb;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.lastHeader {
    width: 120px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}