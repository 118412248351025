.positionContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leftArrow {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100% !important;
    border: 1px solid #f0f0f0 !important;
}

.tabs {
    min-height: 250px;
    display: flex;
    margin-top: 10px;
    background-color: transparent;
    width: 100%;
}

.dimmerTitle {
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.config {
    margin-top: 20px;
}

.sceneSelect {
    margin-top: 10px;
}

.functionBlocksContainer {
    padding-left: 20px;
    padding-right: 20px;
}

.rangeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: -10px;
}

.rangeValue {
    margin-left: 15px;
    font-weight: 500;
}