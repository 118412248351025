.leftArrow {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100% !important;
    border: 1px solid #f0f0f0 !important;
}

.tabs {
    min-height: 250px;
    display: flex;
    margin-top: 10px;
    background-color: transparent;
    width: 100%;
}

.tabContent {
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 600px) {
        margin-left: 3%;
        margin-right: 3%;
    }
}

.nameError {
    margin-left: 5px;
    color: red;
    font-size: 11px;
    font-weight: 500;
}

.lights {
    margin-top: 10px;
}

.sliderDatapoint {
    margin: 10px 8% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.switchDatapoint {
    margin: 10px 8% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sliderDatapointTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;

}