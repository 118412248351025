.mainContainer {
    padding: 12px 15%;

    @media (max-width: 1600px) {
        padding: 12px 10%;
    }

    @media (max-width: 1420px) {
        padding: 12px 5%;
    }

    @media (max-width: 1250px) {
        padding: 12px 30px;
    }
}

.contentContainer {
    min-height: 400px;
    background-color: #e9e9e9;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 500px) {
        margin-top: 40px;
    }
}

.configContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
}

.rangePicker {
    @media (max-width: 800px) {
        margin-top: 10px;
    }
}

.loading {
    position: absolute;
    top: 25%;
    left: 50%
}