.mainContainer {
    min-height: 250px;
    display: flex;
    margin: -24px;
    margin-top: -25px;
    background-color: transparent;
}

.sliderDatapoint {
    margin: 10px 8% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.roundButton {
    width: 36px;
    height: 36px;
    border-radius: 20px;
    padding: 0;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    > img {
        height: 30px;
        width: 30px;
    }
}

.addSwitchPointButton {
    margin-top: 15px;
    width: 30px;
    min-height: 30px;
    padding: 0;
    align-self: center;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 50vh;
}

.overflowAuto {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(202, 202, 202);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 172, 172);
    }
}

.switchPointContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;
}

.removeImg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    z-index: 1;
}

.daysContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
}

.day {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-right: 5px;
}

.roundButton {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    padding: 0;
    margin: -2px 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    > img {
        height: 25px;
        width: 25px;
    }
}

.configElements {
    overflow-y: auto;
    padding: 10px;
    padding-right: 20px;
    padding-top: 0px;
    margin-top: 10px;
    height: 100%;
    width: 45%;
    max-width: 300px;
    max-height: 55vh;

    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(202, 202, 202);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 172, 172);
    }

    @media (max-width: 650px) {
        width: 100%;
        max-width: 100%;
    }
}

.dimmerTitle {
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.config {
    margin-top: 20px;
}

.sceneSelect {
    margin-top: 10px;
}


.tabContent {
    max-width: 300px;
    width: 45%;
    padding-top: 20px;

    @media (max-width: 650px) {
        width: 100%;
        max-width: 100%;
    }
}

.tabContainer {
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 25px;
}

.divider {
    width: 1px;
    border-left: 1px solid #f0f0f0;
}

.editImg {
    height: 30px;
    width: 30px;
    margin-top: -3px;
    margin-right: -2px;
}

.addSwitchPointButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.nameError {
    margin-left: 5px;
    color: red;
    font-size: 11px;
    font-weight: 500;
}

.leftArrow {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100% !important;
    border: 1px solid #f0f0f0 !important;
    border-right: none !important;
}

.mobileClose {
    width: 30%;
    align-self: center;
}

.range {
    margin-bottom: -10px;
    margin-right: 10px;
}