.column {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftArrow {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100% !important;
    border: 1px solid #f0f0f0 !important;
    border-right: none !important;
}

.mainContainer {
    min-height: 250px;
    display: flex;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    @media (max-width: 550px) {
        padding: 0;
    }
}

.chartContainer {
    padding: 25px !important;
    padding-top: 0 !important;
}

.title {
    width: 100%;
    padding: 20px 0;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    
    &::after {
        content: "";
        background-color: #f0f0f0;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 15px;
    }
}

.rangeRow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: 900px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        justify-items: center;
        column-gap: 10px;
    }

    @media (max-width: 550px) {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        justify-items: center;
        column-gap: 10px;
    }
}

.error {
    color: red;
    font-weight: bold;
    font-size: 15;
    padding: 30px;
}

.noDataError {
    color: red;
    font-weight: bold;
    font-size: 13;
    padding: 10;
}

.row {
    display: flex;
}