.img {
    height: 10px;
    width: 10px;
    margin-top: -4px;
}

.progressWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    margin-left: 5px;
}