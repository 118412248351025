.mainContainer {
    display: flex;
    margin-top: -25px;
    margin-bottom: 10px;
    background-color: transparent;
    flex-direction: column;
}

.switchPointContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    padding-bottom: 20px;
    justify-content: space-between;

    border-bottom: 1px solid #f0f0f0;

    @media (max-width: 1020px) {
        flex-direction: column;
    }
}

.switchpointRow {
    display: flex;
    align-items: center;
}

.presenceTitle {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 15px;
}

.inputTitle {
    font-weight: bold;
    font-size: 11px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.inputNumber {
    width: 100% !important;
}

.nameError {
    position: absolute;
    margin-left: 5px;
    color: red;
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap;
}