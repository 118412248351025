.buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.closeImg {
    position: absolute;
    right: 25px;
    top: 25px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.addSwitchPointButton {
    margin-top: 15px;
    width: 30px;
    min-height: 30px;
    padding: 0;
    align-self: center;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(70vh - 116px);
}

.contentOverflow {
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(202, 202, 202);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 172, 172);
    }
}

.switchPointContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
}

.removeImg {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.title {
    font-weight: bold;
    align-self: center;
    margin-bottom: 10px;
    font-size: 15px;
}

.dateTime {
    width: 190px;
}

.addSwitchPointButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}