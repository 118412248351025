.mainContainer {
    padding: 12px 15%;

    @media (max-width: 1600px) {
        padding: 12px 10%;
    }

    @media (max-width: 1420px) {
        padding: 12px 5%;
    }

    @media (max-width: 1250px) {
        padding: 12px 30px;
    }
}

.cards {
    margin-top: 30px;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%
}

.card {
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.cardHeader {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.groupContainer {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @media (max-width: 770px) {
        flex-direction: column;
        text-align: center;
    }
}

.groupName {
    margin-right: 10px;

    @media (max-width: 770px) {
        margin-right: 0px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.buttons {
    display: flex;
    flex-direction: row;

    @media (max-width: 770px) {
        margin-top: 10px;
    }
}

.assignementsName {
    margin-right: 10px;
    white-space: nowrap;

    @media (max-width: 770px) {
        margin-right: 0px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.userName {
    width: 100%;

    @media (max-width: 770px) {
        margin-top: 10px;
    }
}

.editButton {
    margin-left: 10px;
    margin-right: 10px;
}

.changePasswordButton {
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: 500px) {
        margin-right: 0px;
    }
}

.assigneToAllButton {
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: 770px) {
        margin-top: 10px;
    }
}

.addButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.functionUsersSelect {
    margin-left: 10px;

    @media (max-width: 770px) {
        margin-left: 0px;
        margin-top: 10px;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;

    @media (max-width: 500px) {
        flex-direction: column;
    }
}

.removeButton {
    @media (max-width: 770px) {
        margin-top: 10px;
    }
}