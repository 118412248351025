.mainContainer {
    padding: 12px 15%;

    @media (max-width: 1600px) {
        padding: 12px 10%;
    }

    @media (max-width: 1420px) {
        padding: 12px 5%;
    }

    @media (max-width: 1250px) {
        padding: 12px 30px;
    }
}

.version {
    font-size: 10px;
    color: #6e6e6e;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 15px;
    margin-bottom: -5px;
}
