.mainContainer {
    min-height: 190px;
    background-color: white;
    margin: 3px;
    border-radius: 10px;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    padding: 10px 20px 30px 10px;
    min-width: 200px;
    margin-bottom: 10px;
    border: 1px solid #f6f1b1;
}

.mainContainerZeroValue {
    min-height: 100px;
}

.mainContainerOneValue {
    min-height: 120px;
}

.mainContainerTwoValue {
    min-height: 140px;
}

.headerContainer {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 10px;
    font-size: 14px;
}

.titleImg {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleValue {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datapoint {
    font-size: 12px;
    width: 100%;
    margin-left: 20px;
}

.button {
    margin-left: 15px;
    margin-bottom: 5px;
    width: 70%;
}

.datapointsList {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    padding: 0 25px 15px 10px;
    box-shadow: rgb(20 35 85 / 20%) 0px 22px 24px -5px;
}

.dot {
    height: 7px;
    width: 7px;
    margin: 2px;
    background-color: #d4d4d4;
    border-radius: 50%;
    display: inline-block;
}

.dots {
    position: absolute;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 0px 10px;
    width: calc(100% - 16px);
    height: 21px;
}

.sliderDatapoint {
    margin-left: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin-top: 5px;
}

.titleValue {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datapointTop {
    font-size: 12px;
    margin-left: 10px;
}

.value {
    margin-right: 5px;
}

.name {
    width: 90px;
}