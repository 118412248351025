.addSwitchPointButton {
    margin-top: 15px;
    width: 30px;
    min-height: 30px;
    padding: 0;
    align-self: center;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(70vh - 116px);
}

.contentOverflow {
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(202, 202, 202);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 172, 172);
    }
}

.switchPointContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;

    @media (max-width: 680px) {
        flex-direction: column;
    }
}

.removeImg {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.daysContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
}

.day {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-right: 3px;
}

.switchpointRow {
    min-height: 50px;
    display: flex;
    align-items: center;
}

.addSwitchPointButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.switch {
    margin-top: 5px;
}