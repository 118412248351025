.configContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
}

.startButton {
    @media (max-width: 800px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}