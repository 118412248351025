.mainContainer {
    min-height: 150px;
    background-color: white;
    margin: 3px;
    border-radius: 10px;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    padding: 10px 20px 10px 10px;
    min-width: 200px;
    margin-bottom: 10px;
    border: 1px solid #f6f1b1;
}

.headerContainer {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 10px;
    font-size: 14px;
}

.titleImg {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleValue {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button {
    margin-left: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
}

.roundButton {
    margin-top: 8px;
    width: 45px;
    height: 45px;
    border-radius: 22.5px;
}

.moveButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
}

.datapointContainer {
    display: flex;
}

.sliderThumb {
    position: absolute;
    top: 0;
    left: -20px;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 1px 5px #aaaaaa;
    outline: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
}

.buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rotate {
    transform: rotate(180deg);
}

.buttonImg {
    margin-top: 5px;
}

.rotate:active {
    transform: rotate(180deg) translateY(-1px);
}
