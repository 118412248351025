.mainContainer {
    height: 45px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    transition: height 0.3s ease-out;
    @media (max-width: 500px) {
        height: 100px;
    }
}

.twoTab {
    height: 160px;

    @media (max-width: 500px) {
        height: 230px;
    }
}

.onlyOneTab {
    height: 110px;

    @media (max-width: 500px) {
        height: 180px;
    }
}

.subContainer {
    height: 60px;
    border-radius: 10px;
    background-color: #a1a1a1;
    display: flex;
    align-items: center;
    padding: 0 30px;

    @media (max-width: 500px) {
        height: 130px;
        justify-content: space-around;
    }
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
    min-width: 50px;
    white-space: nowrap;

    @media (max-width: 400px) {
        margin-right: 10px;
        white-space: normal;
    }
}

.title {
    margin-left: 5px;
    color: white;
    font-size: 14px;
}

.titleBold {
    font-weight: bold;
}

.titleImg {
    min-width: 24px;
    margin-top: 3px;
}

.roomItem {
    color: #a1a1a1;
    margin: 0 10px;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        height: 20px;
        width: 20px;
    }
}

.bold {
    font-weight: bold;
}

.favoriteContainer {
    @media (max-width: 500px) {
        margin-top: 10px;
    }
}

.floorPlanContainer {
    white-space: nowrap;
    @media (max-width: 500px) {
        margin-top: 5px;
    }

    svg {
        margin-top: 3px;
    }
}

.logoutContainer {
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
        margin-top: 5px;
    }
}

.settingsContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;

    @media (max-width: 400px) {
        margin-right: 10px;
    }
}

.takeSpace {
    @media (min-width: 501px) {
        width: 100%;
    }
}

.categories {
    display: flex;
    width: 100%;
    @media (max-width: 600px) {
        width: auto;
    }
}

.roomsScroll {
    border-bottom: solid 1px #d1d1d1;
}

.categorySelected {
    background-color: #e9e9e9;
    border-radius: 999px;
}

.imgContainer {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categoryImg {
    height: 22px;
    width: 22px;
}

.subTabContainer {
    display: flex;
    align-items: center;
    height: 50px;
}

.mobileMainHeader {
    @media (min-width: 501px) {
        display: flex;
        margin: 10px 0;
    }
}

.lastRoom {
    padding-right: 10px;
}