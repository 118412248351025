.leftArrow {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100% !important;
    border: 1px solid #f0f0f0 !important;
}

.tabs {
    min-height: 250px;
    display: flex;
    margin-top: 10px;
    background-color: transparent;
    width: 100%;
}

.contentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.inputNumber {
    width: 100% !important;
    max-width: 160px;
}

.row {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.inputTitle {
    font-weight: bold;
    font-size: 11px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
}

.separator {
    height: 1px;
    background-color: #dfdede;
    margin: 30px;
}

.energyItemWrapper {
    width: 100%;
    gap: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
}

.propertiesContainer {
    width: 100%;
    overflow-y: auto;
    max-height: 500px;
    padding-bottom: 10px;
}

.timeWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.prioritiesWrapper {
    background-color:rgb(246, 246, 246);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.timesContainer {
    margin-bottom: 20px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-items: center;
}

.addButton {
   margin-left: auto;
   margin-top: 10px;
   width: 100px;
   float: right;
}

.configurationContentWrapper {
    display: flex;
    flex-direction: column;
}

.configurationTitle {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
}

.removeImg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.datePickerWrapper {
    margin: 10px 0;
    background-color:white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    border-radius: 4px;
    width: fit-content;
}

.addCustomButton {
    margin-right: 20px;
}

.removePrioButton {
    position:absolute;
    right: 10px;
    top: 10px;
}

.scheduleCheckboxWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}