.mainContainer {
    min-height: 110px;
    background-color: white;
    margin: 3px;
    border-radius: 10px;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    padding: 10px 20px 10px 10px;
    min-width: 200px;
    margin-bottom: 10px;
    border: 1px solid #f6f1b1;
}

.headerContainer {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 10px;
    font-size: 14px;
}

.titleImg {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleValue {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datapoint {
    font-size: 12px;
    margin-left: 10px;
}

.button {
    margin-left: 15px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modeSelect {
    width: 100%;
    margin: 10px 0 10px 10px;
    font-size: 14px;
}

.sliderDatapoint {
    margin-left: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timeProgramSelectionContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}

.editButton {
    margin-left: 15px;
}

.editImg {
    height: 35px;
    width: 35px;
    margin-top: -3px;
    margin-right: -2px;
}