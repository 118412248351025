.card {
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.title {
    font-weight: 500;
    margin-top: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
}