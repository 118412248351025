.mainContainer {

}

.headerContainer {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 10px;
    font-size: 14px;
}

.titleImg {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleValue {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datapoint {
    font-size: 12px;
    margin-left: 10px;
}

.button {
    margin-left: 15px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderDatapoint {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sliderDatapointTitle {
    font-size: 12px;
    margin-bottom: 8px;
    align-self: flex-start;
}

.span {
    height: 3px;
}

.dpName {
    margin-right: 15px;
}

.groupName {
    margin-top: 15px;
    font-weight: bold;
    text-align: center;
}