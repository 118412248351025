.mainContainer {
    min-width: 250px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.roundButton {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    > img {
        height: 25px;
        width: 25px;
    }
}

.title {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    font-size: 18px;
}

.contentContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;

    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.logoutButton {
    transform: rotate(90deg);
}
