.headerContainer {
    display: flex;
    align-items: center;
}

.titleImg {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleValue {
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
}

.title {
    margin-left: 10px;
    font-size: 14px;
    width: 100%;
}

.value {
    font-size: 12px;
    margin-left: 10px;
}

.favoriteImg {
    position: relative;
    width: auto;
    justify-content: flex-end;
    cursor: pointer;
    z-index: 2;
}

.favoritesContainer {
    width: 30px;
    margin-right: -5px;
    transition: width 0.3s ease-out;
}

.switch {
    margin-left: 5px;
}

.rankingContainer {
    box-shadow: rgb(20 35 85 / 22%) 0px 2px 5px 0px;
    border-radius: 12px;
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 45px;
    top: 13px;
    right: 15px;
    height: 40px;
    z-index: 1;
}

.circle {
    height: 15px;
    width: 15px;
    border-radius: 12px;
    border: 2px solid gray;
    margin-right: 3px;
    cursor: pointer;
}

.circleFilled {
    background-color: gray;
}

.favoriteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.editImg {
    height: 30px;
    width: 30px;
    margin-top: -3px;
    margin-right: -2px;
}

.editButton {
    margin: -5px 3px 0;
}