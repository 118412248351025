.mainContainer {
    min-height: 84%;
    background-color: #e9e9e9;
    position: relative;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    padding: 20px;
}

.triangle {
    position: absolute;
    left: 50px;
    top: -17px;
}

.minWidth {
    min-width: 200px;
    padding: 30px;
    margin: 3px;
}

.loader {
    display: flex;
    justify-content: center;
}

.noControlsToShow {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 12px;
    height: 50px;
}

.modeSelect {
    width: 275px;
    float: right;

    @media (max-width: 450px) {
        width: auto;
    }
}

.settings {
    @media (max-width: 450px) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.controls {
    margin-top: 50px;

    @media (max-width: 450px) {
        margin-top: 10px;
    }
}

.roundButton {
    width: 38px;
    height: 38px;
    margin-left: 8px;
    float: right;
    background-color: white;
}

.buttonImg {
    height: 25px;
    width: 25px;
}