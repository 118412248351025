.mainContainer {
    background-color: white;
    margin: 3px;
    border-radius: 10px;
    box-shadow: rgb(20 35 85 / 10%) 0px 8px 20px 0px;
    padding: 10px 20px 40px 10px;
    min-width: 200px;
    margin-bottom: 10px;
    border: 1px solid #f6f1b1;
    max-height: 77vh;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
        margin: 5px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(202, 202, 202);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(172, 172, 172);
    }
}

.titleText {
    white-space: normal;
}

.headerContainer {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 10px;
    font-size: 14px;
}

.titleImg {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleValue {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datapoint {
    font-size: 12px;
    margin-left: 20px;
    width: 100%;
}

.datapointTop {
    font-size: 12px;
    margin-left: 10px;
    margin-right: -5px;
}

.button {
    margin-left: 15px;
    margin-bottom: 5px;
    width: 70%;
}

.datapointsList {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: calc(100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    padding: 0 25px 15px 10px;
    box-shadow: rgb(20 35 85 / 20%) 0px 22px 24px -5px;
}

.dot {
    height: 7px;
    width: 7px;
    margin: 2px;
    background-color: #d4d4d4;
    border-radius: 50%;
    display: inline-block;
}

.dots {
    position: absolute;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 0px 10px;
    width: calc(100% - 16px);
    height: 21px;
}

.sliderDatapoint {
    margin-left: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.titleText {
    margin-right: 5px;
}
