.button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 0;
    border: solid 1px #d4d4d4;
    color: #5a5a5a;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background-color: #e2e2e2;
}

.button:active {
    transform: translateY(1px);
}

.button:disabled {
    opacity: 0.5;
    cursor: auto;
}

.img {
    cursor: pointer;
}
