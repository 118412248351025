.mainContainer {
    height: 100%;
    width: 100%;
    background-color: #dfdfdf;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.backgroundImg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.6;
}

.loginContainer {
    margin-top: 30px;
    z-index: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    background-color: #686767 !important;
    border: 1px solid #F1E8D9 !important;
    color: #F1E8D9;
}

.mainContainer :global .ant-form-item {
    margin-bottom: 17px !important;
 }


.input:-webkit-autofill,
.input:-webkit-autofill:hover, 
.input:-webkit-autofill:focus, 
.input:-webkit-autofill:active  {
    color-scheme: dark;
    -webkit-box-shadow: 0 0 0 30px #686767 inset !important;
    box-shadow: 0 0 0 30px #686767 inset !important;
}

.input :global .ant-input:-webkit-autofill,
.input :global .ant-input:-webkit-autofill:hover, 
.input :global .ant-input:-webkit-autofill:focus, 
.input :global .ant-input:-webkit-autofill:active  {
    color-scheme: dark;
    -webkit-box-shadow: 0 0 0 30px #686767 inset !important;
    box-shadow: 0 0 0 30px #686767 inset !important;
}

.demo {
    position: absolute;
    right: 20px;
    top: 20px;
}

.demo :global .ant-btn {
    border-color: #BFA889 !important;
}

.demoButton {
    background-color: #BFA889 !important;

}

.logoImg {
    object-fit: scale-down;
    max-width: 220px;
    margin-top: 90px;
    margin-bottom: 20px;
}

.backgroundLoaderContainer {
    position: absolute;
    height: 320px;
    width: 400px;
    left: 50%;
    top: 50%;
    border-radius: 20px;
    background-color: #f5f5f564;
    transform: translate(-50%, -50%);
    z-index: 8;
}

.loaderContaier {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.cloudImg {
    height: 30px;
    margin-right: 15px;
}

.cloudContainer {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 10px;
    color: #e0d9cc;
    background-color: #686767BF;
}

.activeCloudText {
    font-weight: bold;
}

.cloudTextContainer {
    margin-right: 30px;
}

.textContainer {
    color: #F1E8D9;
    font-weight: bold;
    font-size: 11px;
    margin: 0px 0 3px 5px;
}

.submitButton {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 8px;
    background-color: #BFA889 !important;
}

.loginContainer :global .ant-btn {
    border-color: #BFA889 !important
}

.loginContainer :global .ant-input {
    background-color: transparent !important;
    color: #F1E8D9;
}

.loginContainer :global .ant-input-password-icon.anticon {
    color: #F1E8D9
}

.cloudContainer :global .ant-btn {
    background-color: #BFA889 !important;
}

.cloudContainer :global .ant-btn {
    border-color: #BFA889 !important
}