.mainContainer {
    padding: 12px 15%;

    @media (max-width: 1600px) {
        padding: 12px 10%;
    }

    @media (max-width: 1420px) {
        padding: 12px 5%;
    }

    @media (max-width: 1250px) {
        padding: 12px 30px;
    }
}

.floorPlanContainer {
    margin-top: 20px;
    min-height: 84%;
    background-color: white;
    display: flex;
    justify-content: center;
    position: relative;
}

.version {
    font-size: 10px;
    color: #6e6e6e;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 15px;
    margin-bottom: -5px;
}

.loader {
    display: flex;
    justify-content: center;
}

.planContainer {
    position: relative;
    box-shadow: rgb(20 35 85 / 50%) 0px 4px 20px 0px;
}

.controlContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.controlView {
    text-align: left;
}

.control {
    height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 3px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 0px;

    @media (min-width: 500px) {
        white-space: nowrap;
    }
}

.controlBackground {
    background-color: transparent;
    padding: 0;
}

.clearBoxShadow {
    box-shadow: none;
}

.controlName {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 3px;
    color: white;
    padding: 5px;
}

.controlImg {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
